import { Panel } from 'primereact/panel';
import { ScrollTop } from 'primereact/scrolltop';
import {
    FacebookShareButton,
    EmailShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    EmailIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

import './App.css';

function App() {
  let url = window.location.origin;
  return (
    <div className="App">

        <Panel className="Share top-border">
            <div className="p-grid">
                <div className="p-col-12">
                    <h1>
                        #DemandTheTruth
                    </h1>

                    <FacebookShareButton url={url} hashTag="#DemandTheTruth">
                        <FacebookIcon size={48} round />
                    </FacebookShareButton>
                    <TelegramShareButton url={url} title="#DemandTheTruth">
                        <TelegramIcon size={48} round />
                    </TelegramShareButton>
                    <TwitterShareButton url={url} title="#DemandTheTruth">
                        <TwitterIcon size={48} round />
                    </TwitterShareButton>
                    <WhatsappShareButton url={url} title="#DemandTheTruth">
                        <WhatsappIcon size={48} round />
                    </WhatsappShareButton>
                    <RedditShareButton url={url} title="#DemandTheTruth">
                        <RedditIcon size={48} round />
                    </RedditShareButton>
                    <EmailShareButton url={url} subject="#DemandTheTruth">
                        <EmailIcon size={48} round />
                    </EmailShareButton>
                    <h5>
                        support our work (btc) - 13p9pdtiFzdHhQjky3xLT9SmzKAoB5Ba5M
                    </h5>
                    <h5>
                        <a href="https://www.truthseekrs.com">truthseekrs.com</a> // <a href="https://www.yoursay.app">yoursay.app</a>
                    </h5>
                    <h5>
                        contact us - <a href="mailto:info@demandthetruth.xyz">info@demandthetruth.xyz</a>
                    </h5>
                        <iframe src="https://demandthetruth.substack.com/embed" height={"101px"}
                                frameBorder="0"
                                scrolling="no"></iframe>
                </div>
            </div>
        </Panel>
        <div className="Quote">
            <Panel>
            <h1 style={{fontFamily: "Serif"}}>
                "Primum non nocere (First, do no harm) - Hippocrates
            </h1>
            </Panel>
        </div>

        <div className="Menu p-grid">
            <div className="p-col-12 p-md-6 p-lg-3">
                <a href="#safety">
                <Panel header="Safety">
                    <p>There are a plethora of unaddressed and significant safety concerns.</p>
                    <img height="150" src={process.env.PUBLIC_URL + '/safety.png'} />
                </Panel>
                </a>
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
                <a href="#efficacy">
                <Panel header="Efficacy">
                    <p>The effectiveness of the vaccines has dropped dramatically against mutated variants. </p>
                    <img height="150" src={process.env.PUBLIC_URL + '/efficacy.jpeg'} />
                </Panel>
                </a>
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
                <a href="#treatment">
                <Panel header="Treatment">
                        <p>Treatment programs showing significant reduction in hospitalization and death are being ignored.</p>
                    <img height="150" src={process.env.PUBLIC_URL + '/treatment.jpeg'} />
                </Panel>
                </a>
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
                <a href="#censorship">
                    <Panel header="Censorship">
                        <p>Censorship and suppression of the medical community is occurring at unprecedented levels.</p>
                        <img height="150" src={process.env.PUBLIC_URL + '/censorship.png'} />
                    </Panel>
                </a>
            </div>
        </div>

        <Panel className="Content" header="Safety" id="safety">
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'safety/death.png'} />
                    <h3>
                        <p>
                            If, for the first time we are going to rollout a vaccine and ask the whole
                            world to take it, it better be <b><u>nothing less than safe</u></b>.
                        </p>
                        <p>
                            <i>How safe does a vaccine have to be?</i>
                        </p>
                        <p>
                            Let's start with the influenza vaccine, a vaccine which a large proportion of the population
                            receives every year. During the 2020-2021 flu season, approximately 80 million Americans
                            were vaccinated<sup>1</sup>. 165 deaths were reported<sup>2</sup>. This works out to a
                            death rate of 0.21 deaths / 100,000 people. This is considered acceptable from a medical
                            standpoint.
                        </p>
                        <p>
                            <i>How do those figures compare to the COVID-19 vaccines?</i>
                        </p>
                        <p>
                            By the beginning of 2023, approximately 268 million Americans had received at least one vaccination<sup>3</sup>.
                            There were 34,746 reported deaths<sup>4</sup>.  This works out to 7.71 deaths / 100k, a
                            rate <b>37x higher</b> than deaths reported from the influenza vaccine.  Additionally,
                            a temporal relationship exists between vaccination and death, with 50% of deaths reported
                            within 48 hours of vaccination and 80% within a week<sup>4</sup>.
                        </p>
                        <p>
                            By December 2021, the total number of reported deaths associated with the COVID-19 vaccines
                            was more than double the number of deaths associated with <b>all other vaccines combined</b> since
                            the year 1990<sup>5</sup>.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://www.cdc.gov/flu/fluvaxview/dashboard/vaccination-administered.html">
                                https://www.cdc.gov/flu/fluvaxview/dashboard/vaccination-administered.html</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.openvaers.com/openvaers">
                                https://www.openvaers.com/openvaers</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://ourworldindata.org/covid-vaccinations?country=USA">
                                https://ourworldindata.org/covid-vaccinations?country=USA</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://openvaers.com/covid-data/mortality">
                                https://openvaers.com/covid-data/mortality</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://vaersanalysis.info/wp-content/uploads/2022/09/image3_09162022.png">
                                https://vaersanalysis.info/wp-content/uploads/2022/09/image3_09162022.png</a>
                            <br />
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'safety/wch-report.png'} />
                    <h3>
                        <p>
                            The deaths, in both cases, were reported to the Vaccine Adverse Event Reporting System (VAERS).
                            VAERS is a pharmacovigilance system meant to detect vaccine safety risk signals that were not
                            discovered during the initial clinical trials. You'll hear two common arguments against
                            the data in this system:
                        <ol>
                            <li>
                                It is unreliable as anyone can submit a report. However, falsification of an adverse
                                event by an individual or physician is punishable by federal fines, imprisonment, or
                                loss of medical license.
                            </li>
                            <li>
                                Reports do not prove causation (ie: a death reported to VAERS is not necessarily the
                                result of the vaccine).  This is true, VAERS data is correlative only.
                            </li>
                        </ol>
                        </p>
                        <p>
                            Comparing these AEs with other pharmacovigilance systems as well as other vaccines painted a
                            similarly stark picture. V-Safe, whose data was purposely withheld until
                            court order after Freedom of Information Act (FOIA) request<sup>1</sup>, is another
                            pharmacovigilance system which urged participants to report symptoms. Comparing V-Safe
                            reports to VAERS found 17 post-vaccination symptoms were actually under-reported by a factor (URF)
                            of 61x. Of particular note were hospitalization and ER/ED visits which were both
                            under-reported by 10x and 30x respectively<sup>2</sup>. Another report comparing adverse
                            events to the more traditional influenza vaccine showed increased rates of serious adverse
                            events (28x), death (91x), and myocardial infarction (126x)<sup>3</sup>.
                        </p>
                        <p>
                            Similar results were found in the UK VAERS-equivalent system Yellow Card<sup>4</sup>, with a
                            report released in August 2021 concluding the following:
                        </p>
                        <p>
                            <i>
                                "An immediate halt to the vaccination program is required whilst a full and independent
                                safety analysis is undertaken to investigate the full extent of the harms." - Dr. Tess Lawrie
                            </i>
                        </p>
                        <p>
                            Even the World Health Organization's (WHO) own VigiAccess database and the European Union's
                            (EU) EudraVigilance database show the same results, with yet another report released in
                            early 2022<sup>5</sup> concluding the following:
                        </p>
                        <p>
                            <i>
                                "There is sufficient evidence of adverse events relating to Covid-19 vaccines to
                                indicate that a product recall is immediately necessary."
                            </i>
                        </p>
                        <p>
                            Other data sources have been analyzed as well, including the FDA's own Medicare health
                            database, containing information on 25 million people aged 65+.  The results conferred with
                            previous observations, showing increased rates of pulmonary embolism (54%), acute myocardial
                            infarction (42%), disseminated intravascular coagulation (91%), and immune thrombocytopenia
                            (44%) compared to historic rates<sup>6</sup>.
                        </p>
                        <p>
                            Reported adverse events from pharmacovigilance systems around the world all agree that a
                            clear safety signal exists and we knew about it as early as 2021.
                            It is the responsibility of our public health agencies to investigate these correlations and
                            determine if a causal relationship exists. Refusal to perform this function
                            renders the entire purpose of pharmacovigilance useless<sup>7</sup>, and the FDA has been
                            roundly critized for not following up on these potentially serious safety signals<sup>8</sup>.
                            In the past, adverse events reported to VAERS alone were used to suspend the use of the
                            rotavirus vaccine in infants<sup>9</sup>. For the COVID-19 vaccines, however, we've had to
                            rely mostly on independent analysts for answers. In early 2021, an interim report found that
                            the vaccine could not be ruled out in 86% of the first 250 deaths reported to VAERS<sup>10</sup>.
                        </p>
                        <p className="Source">
                                1 -&nbsp;
                                <a href="https://www.icandecide.org/wp-content/uploads/2021/12/001-COMPLAINT-24.pdf">
                                    https://www.icandecide.org/wp-content/uploads/2021/12/001-COMPLAINT-24.pdf</a>
                                <br />
                                2 -&nbsp;
                                <a href="https://vaersanalysis.info/2022/10/08/estimating-the-under-reporting-factor-urf-in-vaers-by-way-of-the-recently-released-v-safe-data">
                                    https://vaersanalysis.info/2022/10/08/estimating-the-under-reporting-factor-urf-in-vaers-by-way-of-the-recently-released-v-safe-data</a>
                                <br />
                                3 -&nbsp;
                                <a href="https://drive.google.com/file/d/1IAzK4a58a7M4ajHKOpqe-9KD9PdYAVpr">
                                    https://drive.google.com/file/d/1IAzK4a58a7M4ajHKOpqe-9KD9PdYAVpr</a>
                                <br />
                                4 -&nbsp;
                                <a href="https://ebmcsquared.org/wp-content/uploads/2021/08/UpdatedReportYellowCardData_20210809.pdf">
                                    https://ebmcsquared.org/wp-content/uploads/2021/08/UpdatedReportYellowCardData_20210809.pdf</a>
                                <br />
                                5 -&nbsp;
                                <a href="https://worldcouncilforhealth.org/resources/covid-19-vaccine-pharmacovigilance-report">
                                    https://worldcouncilforhealth.org/resources/covid-19-vaccine-pharmacovigilance-report</a>
                                <br />
                                6 -&nbsp;
                                <a href="https://www.sciencedirect.com/science/article/pii/S0264410X22014931">
                                    https://www.sciencedirect.com/science/article/pii/S0264410X22014931</a>
                                <br />
                                7 -&nbsp;
                                <a href="https://www.cdc.gov/mmwr/preview/mmwrhtml/mm4843a5.htm">
                                    https://www.cdc.gov/mmwr/preview/mmwrhtml/mm4843a5.htm</a>
                                <br />
                                8 -&nbsp;
                                <a href="https://www.bmj.com/content/379/bmj.o2527">
                                    https://www.bmj.com/content/379/bmj.o2527</a>
                                <br />
                                9 -&nbsp;
                                <a href="https://www.publichealthpolicyjournal.com/_files/ugd/adf864_4588b37931024c5d98e35a84acf8069a.pdf">
                                    https://www.publichealthpolicyjournal.com/_files/ugd/adf864_4588b37931024c5d98e35a84acf8069a.pdf</a>
                                <br />
                                10 -&nbsp;
                                <a href="https://www.researchgate.net/publication/352837543_Analysis_of_COVID-19_vaccine_death_reports_from_the_Vaccine_Adverse_Events_Reporting_System_VAERS_Database_Interim_Results_and_Analysis">
                                    https://www.researchgate.net/publication/352837543_Analysis_of_COVID-19_vaccine_death_reports_from_the_Vaccine_Adverse_Events_Reporting_System_VAERS_Database_Interim_Results_and_Analysis</a>
                                
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid" id="mrna">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'safety/spike-6-month.png'} />
                    <h3>
                        <p>
                            This is the first time in history that an mRNA vaccine has been deployed to the general
                            public. Prior to COVID-19, only two clinical trials consisting of just 458 individuals had
                            ever been inoculated with an mRNA vaccine<sup>1,2</sup>.
                        </p>
                        <p>
                            Like most vaccines, it was originally thought that the nanoparticles delivered by the mRNA
                            vaccines would stay at the injection site.  However, when this was tested in animal models
                            it was found that the nanoparticles circulated throughout the body, concentrating in the
                            adrenals, spleen, and ovaries to a small degree<sup>3,4 (p. 47)</sup>.  This aligns with
                            previous research highlighting potential adverse effects of nanoparticles on the
                            reproductive system<sup>5</sup>.  Additionally, the CDC attest to the fact mRNA lasts just
                            a few days in our bodies<sup>6</sup>. However, multiple studies have found that mRNA and
                            the Spike protein that go along with it were detected in humans much longer than
                            that<sup>7,8,9</sup>. One study, published August 2023, detected Spike in blood a staggering
                            six months post-vaccination<sup>10</sup>.
                        </p>
                        <p>
                            By early 2023 studies had been published questioning the inherent safety of mRNA-based
                            vaccines altogether. One such study concluded that the entire mRNA platform itself may be
                            culpable<sup>11</sup> for vaccine-related AEs. Another found that mRNA in vaccines can
                            disrupt the function of MicroRNAs which can affect severity of disease once contracted,
                            contribute to other longer-term diseases, influence cancer development, and suppress
                            cancer-suppressor genes<sup>12</sup>.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(17)31665-3/fulltext">
                                https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(17)31665-3/fulltext</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.sciencedirect.com/science/article/pii/S0264410X19305626">
                                https://www.sciencedirect.com/science/article/pii/S0264410X19305626</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://report24.news/wp-content/uploads/2021/06/pfizer_Study_pharmacokinetics.pdf">
                                https://report24.news/wp-content/uploads/2021/06/pfizer_Study_pharmacokinetics.pdf</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://www.ema.europa.eu/en/documents/assessment-report/spikevax-previously-covid-19-vaccine-moderna-epar-public-assessment-report_en.pdf">
                                https://www.ema.europa.eu/en/documents/assessment-report/spikevax-previously-covid-19-vaccine-moderna-epar-public-assessment-report_en.pdf</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://www.dovepress.com/potential-adverse-effects-of-nanoparticles-on-the-reproductive-system-peer-reviewed-fulltext-article-IJN">
                                https://www.dovepress.com/potential-adverse-effects-of-nanoparticles-on-the-reproductive-system-peer-reviewed-fulltext-article-IJN</a>
                            <br />
                            6 -&nbsp;
                            <a href="https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/overview-COVID-19-vaccines.html#mrna">
                                https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/overview-COVID-19-vaccines.html#mrna</a>
                            <br />
                            7 -&nbsp;
                            <a href="https://www.cell.com/cell/fulltext/S0092-8674(22)00076-9">
                                https://www.cell.com/cell/fulltext/S0092-8674(22)00076-9</a>
                            <br />
                            8 -&nbsp;
                            <a href="https://onlinelibrary.wiley.com/doi/10.1111/apm.13294">
                                https://onlinelibrary.wiley.com/doi/10.1111/apm.13294</a>
                            <br />
                            9 -&nbsp;
                            <a href="mdpi.com/2227-9059/10/7/1538/htm">
                                mdpi.com/2227-9059/10/7/1538/htm</a>
                            <br />
                            10 -&nbsp;
                            <a href="https://onlinelibrary.wiley.com/doi/full/10.1002/prca.202300048">
                                https://onlinelibrary.wiley.com/doi/full/10.1002/prca.202300048</a>
                            <br />
                            11 -&nbsp;
                            <a href="https://www.mdpi.com/2571-8800/6/2/17">
                                https://www.mdpi.com/2571-8800/6/2/17</a>
                            <br />
                            12 -&nbsp;
                            <a href="https://www.mdpi.com/1422-0067/24/2/1404">
                                https://www.mdpi.com/1422-0067/24/2/1404</a>
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid" id="spike">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'safety/spike.png'} />
                    <h3>
                        <p>
                            The CDC currently describes the Spike protein produced by all of the COVID-19 vaccines as
                            "harmless"<sup>1</sup> to humans despite overwhelming evidence to the contrary.  The
                            pathogenicity of the Spike protein is far reaching, from endothelial and thrombotic
                            disorder<sup>2,3,4,5,6</sup>, to cellular damage and death<sup>7,8</sup>, to inhibition
                            of DNA repair<sup>9</sup>, and even inflammation of the brain itself<sup>10</sup> contributing
                            to long-term neurological complications<sup>11</sup>.
                        </p>
                        <p>
                            The primary argument against this idea is that the Spike protein encoded by the vaccine is locked
                            in it's S1 conformation, whereas the Spike in the wild virus is not.  While
                            true, the S1 protein alone has been shown to exhibit similar effects in the endothelium and
                            brain<sup>12,13,14,15,16,17,18</sup>. As it relates to effects seen post-vaccination, one
                            study found increased endothelial inflammation<sup>19</sup> whereas another found spike antigen
                            concentrations in the blood similar to that of acute infection<sup>20</sup>.  Coincidentally,
                            soluble Spike protein was found to be the cause of the severe thrombotic side effects in the
                            vector-based COVID-19 vaccines<sup>21</sup>.
                        </p>
                        <p>
                            Several studies have since shown how the Spike protein in the vaccines is likely causing
                            harm, calling for an abudance of caution<sup>22</sup> and insist further study be an urgent and
                            vital public health priority<sup>23</sup>.
                        </p>
                        <p>
                            Sadly, even prior to the roll-out of the mass vaccination campaign multiple studies had
                            already cautioned against the use of the Spike protein<sup>24,25,26</sup>, citing uncertainty
                            related to <b><u>long-term safety</u></b>.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/overview-COVID-19-vaccines.html#mrna">
                                https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/overview-COVID-19-vaccines.html#mrna</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.ijbs.com/v08p1237.htm">
                                https://www.ijbs.com/v08p1237.htm</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://www.frontiersin.org/articles/10.3389/fcvm.2021.687783">
                                https://www.frontiersin.org/articles/10.3389/fcvm.2021.687783</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://academic.oup.com/eurheartj/article/42/Supplement_1/ehab724.3383/6391566">
                                https://academic.oup.com/eurheartj/article/42/Supplement_1/ehab724.3383/6391566</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://journals.plos.org/plospathogens/article?id=10.1371/journal.ppat.1009128">
                                https://journals.plos.org/plospathogens/article?id=10.1371/journal.ppat.1009128</a>
                            <br />
                            6 -&nbsp;
                            <a href="https://jhoonline.biomedcentral.com/articles/10.1186/s13045-020-00954-7">
                                https://jhoonline.biomedcentral.com/articles/10.1186/s13045-020-00954-7</a>
                            <br />
                            7 -&nbsp;
                            <a href="https://europepmc.org/article/ppr/ppr232448">
                                https://europepmc.org/article/ppr/ppr232448</a>
                            <br />
                            8 -&nbsp;
                            <a href="https://www.nature.com/articles/s41375-021-01332-z">
                                https://www.nature.com/articles/s41375-021-01332-z</a>
                            <br />
                            9 -&nbsp;
                            <a href="https://www.mdpi.com/1999-4915/13/10/2056">
                                https://www.mdpi.com/1999-4915/13/10/2056</a>
                            <br />
                            10 -&nbsp;
                            <a href="https://www.sciencedirect.com/science/article/pii/S096999612030406X">
                                https://www.sciencedirect.com/science/article/pii/S096999612030406X</a>
                            <br />
                            11 -&nbsp;
                            <a href="https://www.biorxiv.org/content/10.1101/2023.04.04.535604v1.full">
                                https://www.biorxiv.org/content/10.1101/2023.04.04.535604v1.full</a>
                            <br />
                            12 -&nbsp;
                            <a href="https://www.mdpi.com/1999-4915/13/11/2209">
                                https://www.mdpi.com/1999-4915/13/11/2209</a>
                            <br />
                            13 -&nbsp;
                            <a href="https://portlandpress.com/bioscirep/article/41/8/BSR20210611/229418/SARS-CoV-2-spike-protein-S1-induces-fibrin-ogen">
                                https://portlandpress.com/bioscirep/article/41/8/BSR20210611/229418/SARS-CoV-2-spike-protein-S1-induces-fibrin-ogen</a>
                            <br />
                            14 -&nbsp;
                            <a href="https://journals.physiology.org/doi/full/10.1152/ajplung.00223.2021">
                                https://journals.physiology.org/doi/full/10.1152/ajplung.00223.2021</a>
                            <br />
                            15 -&nbsp;
                            <a href="https://www.biorxiv.org/content/10.1101/2021.06.20.448993v1">
                                https://www.biorxiv.org/content/10.1101/2021.06.20.448993v1</a>
                            <br />
                            16 -&nbsp;
                            <a href="https://www.nature.com/articles/s41593-020-00771-8">
                                https://www.nature.com/articles/s41593-020-00771-8</a>
                            <br />
                            17 -&nbsp;
                            <a href="https://www.sciencedirect.com/science/article/pii/S0006291X2100499X">
                                https://www.sciencedirect.com/science/article/pii/S0006291X2100499X</a>
                            <br />
                            18 -&nbsp;
                            <a href="https://www.nature.com/articles/s41598-022-09410-7">
                                https://www.nature.com/articles/s41598-022-09410-7</a>
                            <br />
                            19 -&nbsp;
                            <a href="https://www.ahajournals.org/doi/abs/10.1161/circ.144.suppl_1.10712">
                                https://www.ahajournals.org/doi/abs/10.1161/circ.144.suppl_1.10712</a>
                            <br />
                            20 -&nbsp;
                            <a href="https://www.cell.com/cell/fulltext/S0092-8674(22)00076-9">
                                https://www.cell.com/cell/fulltext/S0092-8674(22)00076-9</a>
                            <br />
                            21 -&nbsp;
                            <a href="https://www.researchsquare.com/article/rs-558954/v1">
                                https://www.researchsquare.com/article/rs-558954/v1</a>
                            <br />
                            22 -&nbsp;
                            <a href="https://www.oncotarget.com/article/28088">
                                https://www.oncotarget.com/article/28088</a>
                            <br />
                            23 -&nbsp;
                            <a href="https://www.cell.com/trends/molecular-medicine/fulltext/S1471-4914(22)00103-4">
                                https://www.cell.com/trends/molecular-medicine/fulltext/S1471-4914(22)00103-4</a>
                            <br />
                            24 -&nbsp;
                            <a href="https://www.mdpi.com/2076-393X/9/1/36">
                                https://www.mdpi.com/2076-393X/9/1/36</a>
                            <br />
                            25 -&nbsp;
                            <a href="https://www.mdpi.com/1424-8220/21/17/5857/htm">
                                https://www.mdpi.com/1424-8220/21/17/5857/htm</a>
                            26 -&nbsp;
                            <a href="https://www.sciencedirect.com/science/article/pii/S1521661620304253">
                                https://www.sciencedirect.com/science/article/pii/S1521661620304253</a>
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'safety/adverse-events.png'} />
                    <h3>
                        <p>
                            In late August 2021, clinical trial data from Moderna (and others) were reanalyzed
                            revealing that 26.19% (3,985) of the 15,210 individuals in Moderna trial
                            suffered a severe adverse event (SAE) within a month of vaccination.  This was 322% higher compared to
                            the control group (943)<sup>1</sup>. In September 2021, a 6-month follow-up of participants
                            in the Pfizer trial was released.  Data showed comparable rates of
                            non-serious adverse events, but a marked increase
                            in severe (+75%) and serious (+10%) adverse events, as well as death (+43%, 20 after
                            unblinding) among the vaccinated group. Notably, four deaths were attributed to cardiac
                            arrest in the vaccinated compared to just one in the placebo group<sup>2</sup>. Another study
                            reanalyzing the clinical trial data found that the excess risk of SAE (12.5 per 10,000) exceeded
                            the risk reduction of COVID-19 hospitalization in both Pfizer and Moderna trials
                            (2.3 and 6.4 per 10,000)<sup>3</sup>.
                        </p>
                        <p>
                            Apart from the clinical trial data no other randomized controlled trial (RCT) exists.  In
                            fact, the clinical trials were actual unblinded (ie: control group all vaccinated) afer just
                            six months, permanently leaving questions about long-term safety unanswered<sup>4</sup>.
                            Despite this, many studies have attempted to answer this question on their own.  One such study
                            analyzed data from 128 countries and found an increase in both COVID-19 related cases and
                            death due directly to the vaccine<sup>5</sup>. Another analyses of 31 countries showed
                            all-cause mortality increased more the higher the vaccination uptake<sup>6</sup>. Other
                            studies found 5 to 10 percent increased mortalities in Japan and Germany<sup>7</sup>, 14% in
                            Australia<sup>8</sup>, following the roll out of the vaccine but not COVID-19 itself. In a
                            further effort to corroborate eixsting evidence, one study went as far as to review 325
                            autopsy cases related to COVID-19 vaccination. Their results showed the cause of death was
                            consistent with known vaccine AEs and concluded high likelihood of a causal link between
                            COVID-19 vaccines and death<sup>9</sup>.

                        </p>
                        <p>
                            The data which exists on the safety of booster shots offers up equally dismaying results. Two
                            studies showed prior COVID-19 infection was associated with a higher risk of adverse event
                            post-vaccination<sup>10,11</sup>.  In fact, one study comparing veterans showed a higher
                            hospitalization rate the more doses they had received<sup>12</sup>.
                        </p>
                        <p>

                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://www.scivisionpub.com/pdfs/us-covid19-vaccines-proven-to-cause-more-harm-than-good-based-on-pivotal-clinical-trial-data-analyzed-using-the-proper-scientific--1811.pdf">
                                https://www.scivisionpub.com/pdfs/us-covid19-vaccines-proven-to-cause-more-harm-than-good-based-on-pivotal-clinical-trial-data-analyzed-using-the-proper-scientific--1811.pdf</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.nejm.org/doi/10.1056/NEJMoa2110345">
                                https://www.nejm.org/doi/10.1056/NEJMoa2110345</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://www.sciencedirect.com/science/article/pii/S0264410X22010283">
                                https://www.sciencedirect.com/science/article/pii/S0264410X22010283</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://www.bmj.com/content/373/bmj.n1244">
                                https://www.bmj.com/content/373/bmj.n1244</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://vector-news.github.io/editorials/CausalAnalysisReport_html.html">
                                https://vector-news.github.io/editorials/CausalAnalysisReport_html.html</a>
                            <br />
                            6 -&nbsp;
                            <a href="https://www.preprints.org/manuscript/202302.0350/v1">
                                https://www.preprints.org/manuscript/202302.0350/v1</a>
                            <br />
                            7 -&nbsp;
                            <a href="https://journals.sciencexcel.com/index.php/mcs/article/view/411/413">
                                https://journals.sciencexcel.com/index.php/mcs/article/view/411/413</a>
                            <br />
                            8 -&nbsp;
                            <a href="https://www.researchgate.net/publication/366445769_Probable_causal_association_between_Australia%27s_new_regime_of_high_all-cause_mortality_and_its_COVID-19_vaccine_rollout">
                                https://www.researchgate.net/publication/366445769_Probable_causal_association_between_Australia%27s_new_regime_of_high_all-cause_mortality_and_its_COVID-19_vaccine_rollout</a>
                            <br />
                            9 -&nbsp;
                            <a href="https://zenodo.org/record/8120771">
                                https://zenodo.org/record/8120771</a>
                            <br />
                            10 -&nbsp;
                            <a href="https://www.journalofinfection.com/article/S0163-4453(21)00277-2/fulltext">
                                https://www.journalofinfection.com/article/S0163-4453(21)00277-2/fulltext</a>
                            <br />
                            11 -&nbsp;
                            <a href="https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2794464">
                                https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2794464</a>
                            <br />
                            12 -&nbsp;
                            <a href="https://academic.oup.com/jid/advance-article/doi/10.1093/infdis/jiad195/7187858">
                                https://academic.oup.com/jid/advance-article/doi/10.1093/infdis/jiad195/7187858</a>
                        </p>
                    </h3>
                </div>
        </div>
        <hr />
        <div className="p-grid" id="myocarditis">
            <div className="p-col-12">
                <img src={process.env.PUBLIC_URL + 'safety/myo-post-vax.png'} />
                <h3>
                        <p>
                            Of all the adverse events, <b>myo/pericarditis</b> remains
                            the most widely discussed to date. You've probably heard that post-vaccination
                            myocarditis is both <i>rare and mild</i>, the science tells a different story:
                            <ul>
                                <li>
                                    Rates of post-vaccination myocarditis range from 16-46/100k in
                                    males aged 12-24 after the second dose<sup>1,2,3</sup>. These rates far exceed
                                    estimations by the CDC (1.9-8.3 cases/100k)<sup>4 (p. 17)</sup>.
                                </li>
                                <li>
                                    Risk/benefit analysis have shown the risk of hospitalization from post-vaccination
                                    myocarditis exceeds that of hospitalization from COVID-19 infection in males aged
                                    12-17<sup>5</sup>.
                                </li>
                                <li>
                                    In a study of 26 million, myocarditis was 4-13x more prevalent in males aged 16-24
                                    after vaccination compared to unvaccinated controls<sup>6</sup>, depending
                                    on vaccine type.
                                </li>
                                <li>
                                    A recent study showed 7/202 (3.5%) adolescent males presented with myo/pericarditis
                                    after the second dose<sup>8</sup>, working out to a staggering rate of 1 in 28.
                                </li>
                            </ul>
                            There is an obvious safety signal here but to this point it has mostly been overlooked and
                            played down, even though factors supporting a causal relationship have been
                            published<sup>9</sup>.  In mice, a study showed that intravenous injection of mRNA vaccine
                            was able to induce myocarditis<sup>10</sup>.
                        </p>
                        <p>
                            Many justify the risk by claiming that rates of myo/pericarditis are higher still after
                            infection than vaccination, however, a study of 200,000 found no difference between the
                            background rate of myocarditis in uninfected compared to those recovering from a COVID-19
                            infection<sup>10</sup>. Moreover, myocarditis was 2-6x more prevalent in males aged 16-24
                            after vaccination compared to COVID-19 infection<sup>6</sup>.  Similar findings were
                            observed in men up to the age of 40, with myocarditis 3-6x more prevalent after vaccination
                            than infection<sup>6, 7</sup>.
                        </p>
                        <p>
                            In terms of severity, several papers have
                            demonstrated the seriousness and long-term impact of this adverse event for males aged
                            12-29.  Firstly, approximately 96% (784/813) of cases required hospitalization<sup>12</sup>.
                            One case study showed the potential for myocardial fibrosis in 100% (13/13) of
                            patients<sup>13</sup>.  Another showed late gadolinium enhancement (LGE), a predictor of
                            cardiovascular death, in 69% (11/16) of patients at 3-8 months follow-up<sup>14</sup>.
                            Another showed 26% (104/393) of individuals recovered were on
                            myocarditis medication, cardiac abnormalities were found in 54% (81/151), and 32% (125/393)
                            were not cleared for physical activity at 90-day follow-up<sup>15</sup>.  Additionally, those
                            hospitalized with myo/pericarditis are told to refrain from competitive athletics and other
                            vigorous exercise for at least 6 months<sup>16</sup>.  More dramatically still are the
                            results of a paper out of Florida, which found an 84% increase in the relative incidence of
                            cardiac-related death among males 18-39 years old within 28 days following mRNA vaccination<sup>17</sup>.
                        </p>
                        <p>
                            Other studies have acknowledged the true long-term risk is unknown and requires more
                            study<sup>18,19,20</sup>. One pre-pandemic study of short/long-term outcomes of myocarditis
                            showed an indicator of heart function (ejection fraction) did not recover to normal levels in
                            50% (99/210) of patients at two year follow-up<sup>21</sup>. From a regulatory perspective,
                            post-marketing surveillance should have caught this safety signal a long time ago but the
                            FDA has stated that existing pharmacovigilance systems are insufficient to assess these
                            serious risks<sup>22</sup>. The risk of this adverse event is made even greater by the fact
                            that we simply don't know what is causing it or why it affects young males
                            disproportionately more than other groups. Such is the risk that a pre-eminent cardiologist
                            published a paper calling for a global suspension of the mRNA vaccines<sup>23</sup>.
                        </p>
                        <p>
                            Never before in medical history has a product so tightly related to severe reactions and
                            death remained on the market and had its use advocated for by trusted public health
                            officials, than the COVID-19 vaccines.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://academic.oup.com/cid/article/75/4/673/6445179">
                                https://academic.oup.com/cid/article/75/4/673/6445179</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2793551">
                                https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2793551</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://onlinelibrary.wiley.com/doi/10.1002/pds.5439">
                                https://onlinelibrary.wiley.com/doi/10.1002/pds.5439</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://www.fda.gov/media/150054/download">
                                https://www.fda.gov/media/150054/download</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://onlinelibrary.wiley.com/doi/10.1111/eci.13759">
                                https://onlinelibrary.wiley.com/doi/10.1111/eci.13759</a>
                            <br />
                            6 -&nbsp;
                            <a href="https://jamanetwork.com/journals/jamacardiology/fullarticle/2791253">
                                https://jamanetwork.com/journals/jamacardiology/fullarticle/2791253</a>
                            <br />
                            7 -&nbsp;
                            <a href="https://www.ahajournals.org/doi/10.1161/CIRCULATIONAHA.122.059970">
                                https://www.ahajournals.org/doi/10.1161/CIRCULATIONAHA.122.059970</a>
                            <br />
                            8 -&nbsp;
                            <a href="https://www.mdpi.com/2414-6366/7/8/196">
                                https://www.mdpi.com/2414-6366/7/8/196</a>
                            <br />
                            9 -&nbsp;
                            <a href="https://www.nature.com/articles/s41467-022-31401-5">
                                https://www.nature.com/articles/s41467-022-31401-5</a>
                            <br />
                            10 -&nbsp;
                            <a href="https://academic.oup.com/cid/article/74/11/1933/6353927">
                                https://academic.oup.com/cid/article/74/11/1933/6353927</a>
                            <br />
                            11 -&nbsp;
                            <a href="https://www.mdpi.com/2077-0383/11/8/2219">
                                https://www.mdpi.com/2077-0383/11/8/2219</a>
                            <br />
                            12 -&nbsp;
                            <a href="https://jamanetwork.com/journals/jama/fullarticle/2788346">
                                https://jamanetwork.com/journals/jama/fullarticle/2788346</a>
                            <br />
                            13 -&nbsp;
                            <a href="https://www.jpeds.com/article/S0022-3476(21)00665-X/fulltext">
                                https://www.jpeds.com/article/S0022-3476(21)00665-X/fulltext</a>
                            <br />
                            14 -&nbsp;
                            <a href="https://www.jpeds.com/article/S0022-3476(22)00282-7/fulltext">
                                https://www.jpeds.com/article/S0022-3476(22)00282-7/fulltext</a>
                            <br />
                            15 -&nbsp;
                            <a href="https://www.thelancet.com/journals/lanchi/article/PIIS2352-4642(22)00244-9/fulltext">
                                https://www.thelancet.com/journals/lanchi/article/PIIS2352-4642(22)00244-9/fulltext</a>
                            <br />
                            16 -&nbsp;
                            <a href="https://www.ahajournals.org/doi/10.1161/CIRCULATIONAHA.113.001372">
                                https://www.ahajournals.org/doi/10.1161/CIRCULATIONAHA.113.001372</a>
                            <br />
                            17 -&nbsp;
                            <a href="https://floridahealthcovid19.gov/wp-content/uploads/2022/10/20221007-guidance-mrna-covid19-vaccines-analysis.pdf">
                                https://floridahealthcovid19.gov/wp-content/uploads/2022/10/20221007-guidance-mrna-covid19-vaccines-analysis.pdf</a>
                            <br />
                            18 -&nbsp;
                            <a href="https://jamanetwork.com/journals/jamacardiology/fullarticle/2783052">
                                https://jamanetwork.com/journals/jamacardiology/fullarticle/2783052</a>
                            <br />
                            19 -&nbsp;
                            <a href="https://www.nejm.org/doi/10.1056/NEJMoa2110475">
                                https://www.nejm.org/doi/10.1056/NEJMoa2110475</a>
                            <br />
                            20 -&nbsp;
                            <a href="https://publications.aap.org/pediatrics/article/148/5/e2021053427/181357">
                                https://publications.aap.org/pediatrics/article/148/5/e2021053427/181357</a>
                            <br />
                            21 -&nbsp;
                            <a href="https://www.ahajournals.org/doi/full/10.1161/CIRCRESAHA.118.313578">
                                https://www.ahajournals.org/doi/full/10.1161/CIRCRESAHA.118.313578</a>
                            <br />
                            22 -&nbsp;
                            <a href="https://www.fda.gov/media/151710/download">
                                https://www.fda.gov/media/151710/download</a>
                            <br />
                            23 -&nbsp;
                            <a href="https://insulinresistance.org/index.php/jir/article/view/71/221">
                                https://insulinresistance.org/index.php/jir/article/view/71/221</a>
                        </p>
                    </h3>
                </div>
            </div>

            <hr />
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'safety/ema-risk.png'} />
                    <h3>
                        <p>
                            Sadly, significant safety concerns just don't seem to end.
                            <ul>
                                <li>
                                    Despite assurances that members of the <i>Data Safety Monitoring Board</i> for the clinical
                                    trials were independent and "beholden to no one"<sup>1</sup>, major conflicts of
                                    interest with Big Pharma were discovered<sup>2</sup>.  In late November 2021, a
                                    whistleblower reported near-daily protocol and regulatory violations, up to and
                                    including data falsification, during Pfizer's clinical trial<sup>3,4</sup>. The FDA,
                                    in example so obvious of ineptitude as a regulatory agency, did not inspect any of
                                    the sites from the complaint<sup>5</sup>.
                                </li>
                                <li>
                                    We are actively vaccinating pregnant women despite excluding them from the
                                    registrational trials.  This is a <b>medical first</b>, we have never before
                                    used a medical product on a group which was excluded from the registrational trials.
                                    Even the European Medical Agencies (EMA) acknowledges that information regarding
                                    vaccination in pregnancy is missing in their own risk management plan<sup>6 (p. 123)</sup>.
                                </li>
                                <li>
                                    In a report to the EMA on vaccine safety, one study found the pregnancy index for
                                    rats was reduced by 9.1%<sup>7 (p. 51)</sup> in the vaccinated group compared to
                                    controls.  There have been more than 30,000 reports of disruptions
                                    in the periods of human females post-vaccination<sup>8</sup>.  Worse still, in
                                    November 2021 a re-analysis of one of the few studies purporting to show the safety
                                    of vaccination in pregnancy found 7- to 8-fold higher than expected rates of
                                    spontaneous abortion following mRNA exposure before 20 weeks<sup>9</sup>.
                                </li>
                                <li>
                                    Over 1,000 case studies regarding side effects such as blood clotting, myocarditis,
                                    pericarditis, thrombosis, thrombocytopenia, anaphylaxis, Bell’s palsy,
                                    Guillain-Barre, cancer, and death following COVID-19 vaccination<sup>10</sup>.
                                </li>
                            </ul>
                        </p>
                        <p className="Source">
                                1 -&nbsp;
                                <a href="https://www.vox.com/21454359/fauci-rand-paul-covid-19-vaccine-trust-cdc-fda">
                                    https://www.vox.com/21454359/fauci-rand-paul-covid-19-vaccine-trust-cdc-fda</a>
                                <br />
                                2 -&nbsp;
                                <a href="https://www.icandecide.org/wp-content/uploads/2020/10/Conflicted-Members-on-DSMBs-for-COVID-19-Vaccines-Final.pdf">
                                    https://www.icandecide.org/wp-content/uploads/2020/10/Conflicted-Members-on-DSMBs-for-COVID-19-Vaccines-Final.pdf</a>
                                <br />
                                3 -&nbsp;
                                <a href="https://www.bmj.com/content/375/bmj.n2635">
                                    https://www.bmj.com/content/375/bmj.n2635</a>
                                <br />
                                4 -&nbsp;
                                <a href="https://cdn.locals.com/documents/47656/47656_e41yu6vd2x117dq.pdf">
                                    https://cdn.locals.com/documents/47656/47656_e41yu6vd2x117dq.pdf</a>
                                <br />
                                5 -&nbsp;
                                <a href="https://www.bmj.com/content/379/bmj.o2628">
                                    https://www.bmj.com/content/379/bmj.o2628</a>
                                <br />
                                6 -&nbsp;
                                <a href="https://www.ema.europa.eu/en/documents/rmp-summary/comirnaty-epar-risk-management-plan_en.pdf">
                                    https://www.ema.europa.eu/en/documents/rmp-summary/comirnaty-epar-risk-management-plan_en.pdf</a>
                                <br />
                                7 -&nbsp;
                                <a href="https://www.ema.europa.eu/en/documents/assessment-report/spikevax-previously-covid-19-vaccine-moderna-epar-public-assessment-report_en.pdf">
                                    https://www.ema.europa.eu/en/documents/assessment-report/spikevax-previously-covid-19-vaccine-moderna-epar-public-assessment-report_en.pdf</a>
                                <br />
                                8 -&nbsp;
                                <a href="https://www.spectator.co.uk/article/The-Covid-vaccines-may-affect-periods.-Are-we-allowed-to-talk-about-this">
                                    https://www.spectator.co.uk/article/The-Covid-vaccines-may-affect-periods.-Are-we-allowed-to-talk-about-this</a>
                                <br />
                                9 -&nbsp;
                                <a href="https://cf5e727d-d02d-4d71-89ff-9fe2d3ad957f.filesusr.com/ugd/adf864_2bd97450072f4364a65e5cf1d7384dd4.pdf">
                                    https://cf5e727d-d02d-4d71-89ff-9fe2d3ad957f.filesusr.com/ugd/adf864_2bd97450072f4364a65e5cf1d7384dd4.pdf</a>
                                <br />
                                10 -&nbsp;
                                <a href="https://www.saveusnow.org.uk/covid-vaccine-scientific-proof-lethal">
                                    https://www.saveusnow.org.uk/covid-vaccine-scientific-proof-lethal</a>
                                <br />
                            </p>
                    </h3>
                </div>
            </div>
        </Panel>

        <div className="Quote">
            <Panel>
                <h1 style={{fontFamily: "Serif"}}>
                    "You are not wrong to question the safety and efficacy of new vaccines.  You are not wrong to be
                    concerned about mandates that seem to infringe on your liberty.  You are not wrong to worry about
                    the influence of big pharma on its own regulatory body, the FDA.  These are good questions, you are
                    being rational, and you are correct by asking them." - Dr. Zubin Damania
                </h1>
            </Panel>
        </div>

        <Panel className="Content" header="Efficacy" id="efficacy">
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'efficacy/efficacy.png'} />
                    <h3>
                        <p>
                            When Pfizer reported the results of its clinical trials efficacy of the vaccine was said
                            to be 95%<sup>1</sup> against the original Wuhan strain.
                            What exactly does that mean and how it is calculated?
                        </p>
                        <p>
                            Essentially, the infection risk of the placebo group was 162/18,325 (0.88%) and the infection
                            risk of the vaccinated group was 8/18,196 (0.044%). Notably, both groups' had a less than 1%
                            risk of infection. The efficacy in this case refers to the <b>relative risk reduction</b>,
                            which is calculated as the percentage reduction in infection risk between groups.  The
                            efficacy of the Moderna vaccine was calculated similarly.
                        </p>
                        <p>
                            Another important figure to consider is <b>absolute risk reduction</b>. It is calculated by
                            subtracting the percentage of positive cases in the vaccinated group from the placebo group.
                            In this case, 0.88% - 0.044% = 0.84%<sup>2</sup> (ie: the placebo group only had a 0.84%
                            higher chance of infection compared to the vaccinated group).  Much less impressive than
                            touting 95% effectiveness, this figure is vital to properly evaluate overall vaccine
                            effectiveness but was not provided in data to the FDA<sup>3</sup>.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://www.pfizer.com/news/press-release/press-release-detail/pfizer-and-biontech-conclude-phase-3-study-covid-19-vaccine">
                                https://www.pfizer.com/news/press-release/press-release-detail/pfizer-and-biontech-conclude-phase-3-study-covid-19-vaccine</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.thelancet.com/journals/lanmic/article/PIIS2666-5247(21)00069-0/fulltext">
                                https://www.thelancet.com/journals/lanmic/article/PIIS2666-5247(21)00069-0/fulltext</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://www.mdpi.com/1648-9144/57/3/199/htm">
                                https://www.mdpi.com/1648-9144/57/3/199/htm</a>
                            <br />
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'efficacy/cfr.png'} />
                    <h3>
                        <p>
                            Before we continue, it is important to start by saying the risk of contracting the disease,
                            hospitalization, and death is heavily influenced by age group.
                        </p>
                        <p>
                            <b>Case Fatality Rate</b> is defined as the proportion of death because of a specific
                            disease among those diagnosed with it.  As we can see by the chart on the right, CFR for
                            COVID-19 is quite low for those under 50 but takes a dramatic jump upwards for each
                            group above 50<sup>1</sup>.
                        </p>
                        <p>
                            CFR is sometimes criticized as it only counts confirmed cases.  The <b>infection fatality
                            rate</b> uses seroprevalence (antibody) data collected from around the world and is
                            generally considered a far more accurate measure of the severity of a virus<sup>2,3,4</sup>.
                            In a follow-up study to IFR originally published by the WHO<sup>5</sup>, data from
                            50 countries suggested an average global IFR as low as ~0.15%<sup>6</sup>.  In other words,
                            COVID-19 for all age groups has a survival rate of 99.85%.
                        </p>
                        <p>
                            Data from the CDC corroborates this research, showing a 25x risk of death for those aged
                            50-64, 60x for those aged 65-74, and 140x for those aged 75-84<sup>7</sup>, when compared
                            to the reference 18-29 age group.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://ourworldindata.org/mortality-risk-covid?#case-fatality-rate-of-covid-19-by-age">
                                https://ourworldindata.org/mortality-risk-covid?#case-fatality-rate-of-covid-19-by-age</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.nature.com/articles/s41586-020-2918-0">
                                https://www.nature.com/articles/s41586-020-2918-0</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://www.bmj.com/content/371/bmj.m4509">
                                https://www.bmj.com/content/371/bmj.m4509</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://link.springer.com/article/10.1007/s10654-020-00698-1">
                                https://link.springer.com/article/10.1007/s10654-020-00698-1</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://www.who.int/bulletin/online_first/BLT.20.265892.pdf">
                                https://www.who.int/bulletin/online_first/BLT.20.265892.pdf</a>
                            <br />
                            6 -&nbsp;
                            <a href="https://onlinelibrary.wiley.com/doi/10.1111/eci.13554">
                                https://onlinelibrary.wiley.com/doi/10.1111/eci.13554</a>
                            <br />
                            7 -&nbsp;
                            <a href="https://www.cdc.gov/coronavirus/2019-ncov/covid-data/investigations-discovery/hospitalization-death-by-age.html">
                                https://www.cdc.gov/coronavirus/2019-ncov/covid-data/investigations-discovery/hospitalization-death-by-age.html</a>
                            <br />
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid" id="waning">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'efficacy/overtime.jpeg'} />
                    <h3>
                        <p>
                            A big question at the beginning of the pandemic was whether vaccinating could
                            protect others. This argument was used as a justification for vaccine mandates even
                            as data was published showing the vaccines are as ineffective as they are unsafe.
                        </p>
                        <p>
                            As soon as the first major variant, Delta, overtook the original Wuhan strain studies were
                            already showing substantial declines in vaccine effectiveness
                            less than six months post-vaccination<sup>2,3,4,5,6,7,8,9,10,11</sup>. After the
                            emergence of the Omicron variant, studies showed protection waned even more
                            quickly. Vaccine effectiveness against Omicron declines to less than ~20% 2-3 months
                            <sup>12,13,14,15,16</sup> post-vaccination. Subsequent doses of vaccine (3+) fared no
                            better, showing similarly rapid decreases in efficacy<sup>17, 18</sup> and
                            effectiveness<sup>19,20,21</sup>. Around a similar timeframe, Omicron-targeting (BA.4/BA.5)
                            bivalent vaccines were authorized under emergency use.  Studies quickly showed these new
                            vaccines were neither more effective than the original vaccines<sup>22,23</sup>, but also
                            were not effective against circulating variants (XBB.1, BA.2.75.2, BQ.1.1) at the
                            time<sup>24,25,26</sup>.
                        </p>
                        <p>
                            Another question had to do with whether or not vaccination reduced overall viral load. Data
                            showed that once infected with Delta the viral load was similar regardless of vaccination
                            status<sup>27,28,29</sup>, with fully vaccinated individuals capable of efficiently
                            transmitting infection<sup>30</sup>. Another study found that boosted individuals were 3x
                            more likely than unvaccinated to shed live virus after 10 days<sup>31</sup>.  In one study
                            which looked at data from 68 countries, no meaningful relationship between percentage of the
                            population fully vaccinated and new COVID-19 cases could be found<sup>32</sup>.
                        </p>
                        <p>
                            As you read this keep in mind that the FDA's own guidelines state that the efficacy of
                            COVID-19 vaccines must be at least 50%<sup>33 (p. 17)</sup>.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(21)01290-3/fulltext">
                                https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(21)01290-3/fulltext</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.ejinme.com/article/S0953-6205(21)00271-5/fulltext">
                                https://www.ejinme.com/article/S0953-6205(21)00271-5/fulltext</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://www.naturalnews.com/files/Salus_Humetrix_VE_study_2021_09_28.pdf">
                                https://www.naturalnews.com/files/Salus_Humetrix_VE_study_2021_09_28.pdf</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://www.science.org/doi/10.1126/science.abl9551">
                                https://www.science.org/doi/10.1126/science.abl9551</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3949410">
                                https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3949410</a>
                            <br />
                            6 -&nbsp;
                            <a href="https://www.science.org/doi/10.1126/science.abm0620">
                                https://www.science.org/doi/10.1126/science.abm0620</a>
                            <br />
                            7 -&nbsp;
                            <a href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736%2821%2902183-8">
                                https://www.thelancet.com/journals/lancet/article/PIIS0140-6736%2821%2902183-8</a>
                            <br />
                            8 -&nbsp;
                            <a href="https://www.medrxiv.org/content/10.1101/2021.08.06.21261707v3">
                                https://www.medrxiv.org/content/10.1101/2021.08.06.21261707v3</a>
                            <br />
                            9 -&nbsp;
                            <a href="https://www.nejm.org/doi/full/10.1056/NEJMoa2114114">
                                https://www.nejm.org/doi/full/10.1056/NEJMoa2114114</a>
                            <br />
                            10 -&nbsp;
                            <a href="https://www.cell.com/med/fulltext/S2666-6340(21)00406-2">
                                https://www.cell.com/med/fulltext/S2666-6340(21)00406-2</a>
                            <br />
                            11 -&nbsp;
                            <a href="https://www.mdpi.com/2076-393X/10/1/64">
                                https://www.mdpi.com/2076-393X/10/1/64</a>
                            <br />
                            12 -&nbsp;
                            <a href="https://www.nature.com/articles/s41591-022-01753-y">
                                https://www.nature.com/articles/s41591-022-01753-y</a>
                            <br />
                            13 -&nbsp;
                            <a href="https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2792295">
                                https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2792295</a>
                            <br />
                            14 -&nbsp;
                            <a href="https://www.science.org/doi/10.1126/science.abq1841">
                                https://www.science.org/doi/10.1126/science.abq1841</a>
                            <br />
                            15 -&nbsp;
                            <a href="https://www.nejm.org/doi/full/10.1056/NEJMoa2210058">
                                https://www.nejm.org/doi/full/10.1056/NEJMoa2210058</a>
                            <br />
                            16 -&nbsp;
                            <a href="https://link.springer.com/article/10.1007/s10654-021-00808-7">
                                https://link.springer.com/article/10.1007/s10654-021-00808-7</a>
                            <br />
                            17 -&nbsp;
                            <a href="https://www.nejm.org/doi/full/10.1056/NEJMoa2201570">
                                https://www.nejm.org/doi/full/10.1056/NEJMoa2201570</a>
                            <br />
                            18 -&nbsp;
                            <a href="https://www.nejm.org/doi/10.1056/NEJMc2202542">
                                https://www.nejm.org/doi/10.1056/NEJMc2202542</a>
                            <br />
                            19 -&nbsp;
                            <a href="https://www.bmj.com/content/377/bmj-2022-071113">
                                https://www.bmj.com/content/377/bmj-2022-071113</a>
                            <br />
                            20 -&nbsp;
                            <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2211283">
                                https://www.nejm.org/doi/full/10.1056/NEJMc2211283</a>
                            <br />
                            21 -&nbsp;
                            <a href="https://www.sciencedirect.com/science/article/pii/S0264410X23003158">
                                https://www.sciencedirect.com/science/article/pii/S0264410X23003158</a>
                            <br />
                            22 -&nbsp;
                            <a href="https://www.thelancet.com/journals/laninf/article/PIIS1473-3099(23)00181-0/fulltext">
                                https://www.thelancet.com/journals/laninf/article/PIIS1473-3099(23)00181-0/fulltext</a>
                            <br />
                            23 -&nbsp;
                            <a href="https://www.biorxiv.org/content/10.1101/2022.10.22.513349v1">
                                https://www.biorxiv.org/content/10.1101/2022.10.22.513349v1</a>
                            <br />
                            24 -&nbsp;
                            <a href="https://www.nature.com/articles/s41591-022-02162-x">
                                https://www.nature.com/articles/s41591-022-02162-x</a>
                            <br />
                            25 -&nbsp;
                            <a href="https://www.cell.com/cell/fulltext/S0092-8674(22)01531-8">
                                https://www.cell.com/cell/fulltext/S0092-8674(22)01531-8</a>
                            <br />
                            26 -&nbsp;
                            <a href="https://www.nature.com/articles/s41591-022-02162-x">
                                https://www.nature.com/articles/s41591-022-02162-x</a>
                            <br />
                            27 -&nbsp;
                            <a href="https://academic.oup.com/ofid/article/9/5/ofac135/6550312">
                                https://academic.oup.com/ofid/article/9/5/ofac135/6550312</a>
                            <br />
                            28 -&nbsp;
                            <a href="https://journals.plos.org/plospathogens/article?id=10.1371/journal.ppat.1010876">
                                https://journals.plos.org/plospathogens/article?id=10.1371/journal.ppat.1010876</a>
                            <br />
                            29 -&nbsp;
                            <a href="https://linkinghub.elsevier.com/retrieve/pii/S0264410X2201458X">
                                https://linkinghub.elsevier.com/retrieve/pii/S0264410X2201458X</a>
                            <br />
                            30 -&nbsp;
                            <a href="https://www.thelancet.com/journals/laninf/article/PIIS1473-3099(21)00648-4">
                                https://www.thelancet.com/journals/laninf/article/PIIS1473-3099(21)00648-4</a>
                            <br />
                            31 -&nbsp;
                            <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2202092">
                                https://www.nejm.org/doi/full/10.1056/NEJMc2202092</a>
                            <br />
                            32 -&nbsp;
                            <a href="https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2796615">
                                https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2796615</a>
                            <br />
                            33 -&nbsp;
                            <a href="https://www.fda.gov/media/139638/download">
                                https://www.fda.gov/media/139638/download</a>
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid" id="igg4">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'efficacy/uk.png'} />
                    <h3>
                        <p>
                            To deflect from neglible protection against symptomatic disease, the benefit of
                            vaccination has now shifted to protection against severe disease.  However, even this is disputed,
                            with one study concluding the very claim itself lacked empiric evidence<sup>1</sup>.  Other studies have shown
                            protection against hospitalization from the original, monovalent vaccines (2/3 dose) declined to
                            ~30% after 4-5 months<sup>2,3</sup>, whereas protection from natural immunity remained
                            as high as ~60% after 10 months<sup>3</sup>.  Even the CDC's own data showed protection against
                            hospitalization declined to 21% just 4 months after bivalent vaccination<sup>4</sup>.
                        </p>
                        <p>
                            On the topic of natural immunity, you've probably heard it compared to the immunity
                            gained from vaccination.  Let's be very clear: <b>natural immunity has always been the gold
                            standard in immunity</b>. COVID-19 is no different.  As early as October 2021, over 160
                            studies had already shown natural infection leads to a robust, long-lived immune memory, and
                            that those with natural immunity were unlikely to benefit from vaccination<sup>5</sup>. Yet
                            despite the extent of the evidence the CDC continues to <b>misinform</b> the public as a
                            matter of national policy, using just a single study to proclaim vaccination indeed offers
                            better protection than natural immunity<sup>6</sup>.
                        </p>
                        <p>
                            From here, the data on <i>effectiveness</i> becomes highly concerning.  In late 2022, a
                            study showed that vaccine effectiveness actually became negative, meaning that vaccinated
                            individuals had higher rates of infection than unvaccinated<sup>7</sup>. Other studies have
                            confirmed these findings, demonstrating rates of infection were similar to or higher than
                            unvaccinated 5-6 months post-vaccination<sup>8,9</sup>. Even more studies have shown that the risk of
                            contracting COVID-19 goes up with each vaccination received<sup>11,12</sup>
                        </p>
                        <p>
                            The burning question became: How could a vaccine possibly cause those who took it to become
                            more likely to become infected than those that didn't?  By early 2023, answers started to
                            emerge.  Two studies had discovered that the immune systems of
                            individuals who had previously received two or more vaccines had undergone a dramatic shift.
                            Specifically, they showed that the neutralizing IgG1/3 antibodies were being replaced by
                            non-neutralizing, tolerant IgG4<sup>12,13</sup>. A further study, released in August 2023,
                            showed that after the immune response after the third dose was made up of ~40% IgG4 antibodies<sup>14</sup>.
                            The repercussions of such a change in the immune response to COVID-19 cannot be understated.
                            IgG4 antibodies prevent elimination of the virus, leading to persistent and chronic infection<sup>15</sup>.
                            High levels of IgG4 is a predictor of COVID-19 mortality<sup>16</sup>. Additionally, high
                            levels of IgG4 can also lead to auto-immunity, autoimmune myocarditis, and promote cancer growth<sup>17</sup>.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://www.jpands.org/vol28no1/ophir.pdf">
                                https://www.jpands.org/vol28no1/ophir.pdf</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.nejm.org/doi/full/10.1056/NEJMc2210093">
                                https://www.nejm.org/doi/full/10.1056/NEJMc2210093</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://www.medrxiv.org/content/10.1101/2023.01.18.23284739v1">
                                https://www.medrxiv.org/content/10.1101/2023.01.18.23284739v1</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://www.cdc.gov/vaccines/acip/meetings/downloads/slides-2023-09-12/05-COVID-Link-Gelles-508.pdf">
                                https://www.cdc.gov/vaccines/acip/meetings/downloads/slides-2023-09-12/05-COVID-Link-Gelles-508.pdf</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://brownstone.org/articles/research-studies-affirm-naturally-acquired-immunity">
                                https://brownstone.org/articles/research-studies-affirm-naturally-acquired-immunity</a>
                            <br />
                            6 -&nbsp;
                            <a href="https://www.cdc.gov/media/releases/2021/s0806-vaccination-protection.html">
                                https://www.cdc.gov/media/releases/2021/s0806-vaccination-protection.html</a>
                            <br />
                            7 -&nbsp;
                            <a href="https://www.medrxiv.org/content/10.1101/2022.06.28.22276926v4">
                                https://www.medrxiv.org/content/10.1101/2022.06.28.22276926v4</a>
                            <br />
                            8 -&nbsp;
                            <a href="https://www.bmj.com/content/378/bmj-2022-071249">
                                https://www.bmj.com/content/378/bmj-2022-071249</a>
                            <br />
                            9 -&nbsp;
                            <a href="https://www.nature.com/articles/s41467-023-35815-7">
                                https://www.nature.com/articles/s41467-023-35815-7</a>
                            <br />
                            10 -&nbsp;
                            <a href="https://academic.oup.com/ofid/article/10/6/ofad209/7131292">
                                https://academic.oup.com/ofid/article/10/6/ofad209/7131292</a>
                            <br />
                            11 -&nbsp;
                            <a href="https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2794886">
                                https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2794886</a>
                            <br />
                            12 -&nbsp;
                            <a href="https://www.science.org/doi/10.1126/sciimmunol.ade2798">
                                https://www.science.org/doi/10.1126/sciimmunol.ade2798</a>
                            <br />
                            13 -&nbsp;
                            <a href="https://www.frontiersin.org/articles/10.3389/fimmu.2022.1020844/full">
                                https://www.frontiersin.org/articles/10.3389/fimmu.2022.1020844/full</a>
                            <br />
                            14 -&nbsp;
                            <a href="https://www.nature.com/articles/s41598-023-40103-x">
                                https://www.nature.com/articles/s41598-023-40103-x</a>
                            <br />
                            15 -&nbsp;
                            <a href="https://www.mdpi.com/2218-273X/13/9/1338">
                                https://www.mdpi.com/2218-273X/13/9/1338</a>
                            <br />
                            16 -&nbsp;
                            <a href="https://www.ejinme.com/article/S0953-6205(21)00312-5/fulltext">
                                https://www.ejinme.com/article/S0953-6205(21)00312-5/fulltext</a>
                            <br />
                            17 -&nbsp;
                            <a href="https://www.mdpi.com/2076-393X/11/5/991">
                                https://www.mdpi.com/2076-393X/11/5/991</a>
                            <br />
                        </p>
                    </h3>
                </div>
            </div>
        </Panel>

        <div className="Quote">
            <Panel>
                <h1 style={{fontFamily: "Serif"}}>
                    "There is no medication that has been proven to be more effective than Ivermectin for the treatment of SARS-CoV-2." - Dr. Paul Marik
                </h1>
            </Panel>
        </div>

        <Panel className="Content" header="Treatment" id="treatment">
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'treatment/treatment.jpeg'} />
                    <h3>
                        <p>
                            By March 2020, the WHO, NIH, and CDC had not provided any guidance to clinicians on how to
                            treat the disease.  Instead of treatment, we became conditioned to wear a mask, wait in
                            isolation, and be saved by the vaccine.
                        </p>
                        <p>
                            Due to this lack of guidance, a collaboration of almost 60 doctors from around the world
                            culminated in the world's first multi-drug, early treatment protocol for COVID-19<sup>1</sup>.
                        </p>
                        <p>
                            One drug included in that treatment protocol was hydroxychloroquine.  Data from nine studies
                            found hydroxychloroquine-based multi-drug regimens were associated with a 60% reduction in
                            mortality in nursing homes<sup>2</sup>.  Another study found that when this multi-drug regimen
                            was used in early treatment, hospitalizations were reduced by 85% and deaths by 75%<sup>3</sup>.
                        </p>
                        <p>
                            Even now, our health institutions have no plans for large-scale trials in
                            the use of any of the drugs within the multi-drug treatment protocol. Medical experts
                            have no choice but to act on clinical judgment supported by their understanding of
                            pathophysiology and the totality of the evidence concerning therapy<sup>4</sup>.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://rcm.imrpress.com/article/2020/2153-8174/RCM2020264.shtml">
                                https://rcm.imrpress.com/article/2020/2153-8174/RCM2020264.shtml</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.sciencedirect.com/science/article/pii/S0306987721001419">
                                https://www.sciencedirect.com/science/article/pii/S0306987721001419</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://www.imrpress.com/journal/RCM/21/4/10.31083/j.rcm.2020.04.260">
                                https://www.imrpress.com/journal/RCM/21/4/10.31083/j.rcm.2020.04.260</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://www.amjmed.com/article/S0002-9343(21)00084-X/fulltext">
                                https://www.amjmed.com/article/S0002-9343(21)00084-X/fulltext</a>
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'treatment/groups.png'} />
                    <h3>
                        <p>
                            As a result of the failure of our public health agencies, many early treatment groups have
                            sprung up all over the world.
                        </p>
                        <div style={{fontSize: "0.8em"}}>
                            <p>
                                <a href="https://covid19criticalcare.com/">Front Line COVID-19 Critical Care Alliance</a>
                                <br />
                                Formed by leading critical care specialists in March 2020, at the beginning of the
                                Coronavirus pandemic, the ‘Front Line COVID-19 Critical Care Alliance’ is now a 501(c)(3)
                                non-profit organization dedicated to developing highly effective treatment protocols to
                                prevent the transmission of COVID-19 and to improve the outcomes for patients ill with the disease.
                            </p>
                            <p>
                                <a href="https://www.canadiancovidcarealliance.org/">Canadian Covid Care Alliance</a>
                                <br />
                                Our alliance of independent Canadian doctors, scientists and health care practitioners is
                                committed to providing top-quality and balanced evidence-based information to the
                                Canadian public about COVID-19 so that hospitalizations can be reduced, lives saved, and
                                our country safely restored as quickly as possible.
                            </p>
                            <p>
                                <a href="https://aapsonline.org/">Association of American Physicians and Surgeons</a>
                                <br />
                                A non-partisan professional association of physicians in all types of practices and
                                specialties across the country. Since 1943, AAPS has been dedicated to the highest ethical
                                standards of the Oath of Hippocrates and to preserving the sanctity of the patient-physician
                                relationship and the practice of private medicine.
                            </p>
                            <p>
                                <a href="https://bird-group.org/">British Ivermectin Recommendation Development Group</a>
                                <br />
                                A truly grassroots initiative bringing together clinicians, health researchers and patient
                                representatives from all around the world to advocate for the use of Ivermectin against
                                COVID-19.
                            </p>
                            <p>
                                <a href="https://www.truthforhealth.org/">Truth For Health Foundation</a>
                                <br />
                                A physician-founded 501(c)3 public charitable foundation created to provide truthful,
                                balanced, medically sound, research-based information and cutting edge updates on prevention
                                and treatment of common medical conditions, including COVID-19.
                            </p>
                            <p>
                                <a href="https://www.terapiadomiciliarecovid19.org/">Terapia Domiciliare</a>
                                <br />
                                An informal group of citizens and doctors born to provide support to citizens during the
                                COVID-19 emergency, to exchange clinical information and develop a home care protocol in the
                                absence of specific directives.
                            </p>
                            <p>
                                <a href="https://covidmedicalnetwork.com/">Covid Medical Network</a>
                                <br />
                                A group of Australian senior medical doctors and health professionals concerned with the health impacts
                                of lockdowns, as well as the lack of good information available to the general public in
                                regards to COVID-19.
                            </p>
                        </div>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'treatment/ivm.png'} />
                    <h3>
                        <p>
                            On the topic of treatment, one drug stands out from the rest in the amount of censorship
                            and resistance it has encountered, Ivermectin.
                        </p>
                        <p>
                            Ivermectin was discovered in 1975 and its inventors won a Nobel Prize for it in 2015.
                            It is on the WHO's "List of Essential Medicines" and over
                            3.7 billion doses have been distributed since 1987.  Since its development 40 years ago it
                            has made historic impacts on global health by eradicating pandemics of parasitic diseases
                            across multiple continents.  It's track record is so strong
                            at eradicating diseases and being used in new ways that it is often referred to as a "wonder"
                            drug<sup>1</sup>.
                        </p>
                        <p>
                            Regarding the totality of the evidence, from in-vitro, in-vivo, in-silico, pharmacologic,
                            clinical observations, observational controlled trials, meta-analyses of randomized controlled trials,
                            to epidemiological studies, have all shown benefit in regards to the treatment of COVID-19<sup>2</sup>.
                        </p>
                        <p>
                            As of March 2022, there have been 79 studies regarding the treatment and prophylaxis of
                            COVID-19 with Ivermectin from 766 scientists with 85,814 patients. Overall, these
                            studies have shown statistically significant improvements in mortality, hospitalization,
                            recovery, cases, and viral clearance<sup>3</sup>. Ivermectin is currently being used as a
                            treatment for COVID-19 widely in 18 countries around the world<sup>4</sup>.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://www.jstage.jst.go.jp/article/pjab/87/2/87_2_13/_article">
                                https://www.jstage.jst.go.jp/article/pjab/87/2/87_2_13/_article</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://covid19criticalcare.com/wp-content/uploads/2021/08/SUMMARY-OF-THE-EVIDENCE-BASE-FINAL.pdf">
                                https://covid19criticalcare.com/wp-content/uploads/2021/08/SUMMARY-OF-THE-EVIDENCE-BASE-FINAL.pdf</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://ivmmeta.com/#fig_fpall">
                                https://ivmmeta.com/#fig_fpall</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://ivmstatus.com/">
                                https://ivmstatus.com/</a>
                            <br />
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'treatment/pandemic.pbm'} />
                    <h3>
                        <p>
                            In May 2021, the Indian Bar Association sued the World Health Organization for deliberately
                            suppressing data regarding effectiveness of the drug Ivermectin, with an intent
                            to dissuade the people of India from using it<sup>1</sup>.  Despite overwhelming scientific
                            evidence of its effectiveness at treating COVID-19, the current stance of the WHO is that it
                            should only be used in clinical trials<sup>2</sup>.  On platforms such as Youtube, your content
                            will be removed if it recommends the use of the drug, regardless of your credentials<sup>3</sup>.
                            In late August 2021, a press conference by the chairman of the Tokyo Medical Association
                            recommending the use of Ivermectin was deleted from Youtube<sup>4</sup>.
                        </p>
                        <p>
                            Still today, early treatment protocols are roundly criticized and blanketed as "not effective"
                            or "unproven".  Yet, doctors from all around the world are fighting just to be able to use them.
                            The reasons as to why such vehement opposition to these protocols exist are not obvious, but
                            calling into question whether conflicts of interest play a role would not be unreasonable.  Pharmaceutical
                            companies such as Pfizer and Moderna stand to profit almost $50 billion dollars from the sale
                            of vaccines<sup>5</sup>, however, the authorization of those vaccines is predicated on the
                            basis that no other effective treatments exist<sup>6 (p. 3)</sup>.
                        </p>
                        <p>
                            If a cheap, generic drug like Ivermectin were to be recognized as an effective
                            treatment for COVID-19, it would jeopardize the rollout of the vaccine program. Simply put,
                            it would cost the pharmaceutical companies too much money<sup>7</sup>. Medicine has become
                            politicized.  Ask yourself why, in a global pandemic, our governmental agencies hold more
                            power than our medical experts?  While the list of crimes committed by authorities during
                            the COVID-19 pandemic is a long one, perhaps the biggest crime of all is the purposeful
                            suppression of safe and effective treatments<sup>8</sup>, and the many thousands of human
                            lives that have been lost as a result.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://indianbarassociation.in/wp-content/uploads/2021/05/IBA-PRESS-RELEASE-MAY-26-2021.pdf">
                                https://indianbarassociation.in/wp-content/uploads/2021/05/IBA-PRESS-RELEASE-MAY-26-2021.pdf</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://app.magicapp.org/#/guideline/nBkO1E/section/LAQX7L">
                                https://app.magicapp.org/#/guideline/nBkO1E/section/LAQX7L</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://support.google.com/youtube/answer/9891785">
                                https://support.google.com/youtube/answer/9891785</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://halturnerradioshow.com/index.php/en/news-page/world/breaking-news-chairman-of-tokyo-medical-association-tells-doctors-to-prescribe-ivermectin-for-covid-treatment">
                                https://halturnerradioshow.com/index.php/en/news-page/world/breaking-news-chairman-of-tokyo-medical-association-tells-doctors-to-prescribe-ivermectin-for-covid-treatment</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://www.reuters.com/breakingviews/booster-jabs-are-easy-money-pfizer-moderna-2021-08-13/">
                                https://www.reuters.com/breakingviews/booster-jabs-are-easy-money-pfizer-moderna-2021-08-13/</a>
                            <br />
                            6 -&nbsp;
                            <a href="https://www.fda.gov/media/144412/download">
                                https://www.fda.gov/media/144412/download</a>
                            <br />
                            7 -&nbsp;
                            <a href="https://www.biznews.com/thought-leaders/2021/05/12/mailbox-ivermectin">
                                https://www.biznews.com/thought-leaders/2021/05/12/mailbox-ivermectin</a>
                            <br />
                            8 -&nbsp;
                            <a href="https://www.lifesitenews.com/opinion/the-biggest-crime-committed-during-the-vaccine-heist-is-the-censorship-of-ivermectin/">
                                https://www.lifesitenews.com/opinion/the-biggest-crime-committed-during-the-vaccine-heist-is-the-censorship-of-ivermectin/</a>
                            <br />
                        </p>
                    </h3>
                </div>
            </div>
        </Panel>

        <Panel className="Content" header="Censorship" id="censorship">
            <div className="p-grid">
                <div className="p-col-12">
                    <h3>
                        <img src={process.env.PUBLIC_URL + 'censorship/tni_bbc.png'} />
                        <p>
                            If you are getting this information for the first time, you must ask yourself why. How
                            could it be that such important information does not see the light of day?  A large part of
                            the answer lies within the <i>"Trusted News Initiative"</i>, created on December 10th,
                            2020<sup>1</sup>.
                        </p>
                        <p>
                            It was a partnership between the corporate media (CNN, CNBC, ABC, etc) as well as
                            social media and local media whose goal was to produce, <i>solely</i>, information promoting
                            vaccines and the use of vaccines.
                        </p>
                        <p>
                            It is unknown whether this initiative was created in good faith but it's proliferation led to
                            the suppression of all <i>dissenting opinions</i>, including those of medical experts
                            around the world, regarding <b><u>vaccine safety</u></b> and <b><u>early treatment
                            programs</u></b>.
                        </p>
                        <p>
                            Now, we only ever hear one story on the vaccine: <b>It's good for you, take it</b>.  This
                            initiative created a single, indestructible narrative.  Anything that comes out counter
                            to this narrative, even if it comes from medical experts, is quickly and systematically
                            dismissed as "misinformation".
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href ="https://www.bbc.com/mediacentre/2020/trusted-news-initiative-vaccine-disinformation">
                                https://www.bbc.com/mediacentre/2020/trusted-news-initiative-vaccine-disinformation</a>
                        </p>
                    </h3>
                </div>
            </div>
            <hr />
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'censorship/silenced.png'} style={{float: "right", width: "50%", padding: "30px"}} />
                    <h3>
                        <p>
                            Doctors all around the world doctors have been chastised, suspended, and even jailed for
                            using simple medications in an attempt to reduce hospitalization and mortality in their
                            patients.
                        </p>
                        <p>
                            In Australia, if a doctor prescribes an outpatient medicine, hydroxychloroquine, that
                            doctor could face imprisonment<sup>1</sup>.  France followed suit by banning
                            it's use outright<sup>2</sup>. In the Philippines, prescribing Ivermectin could result in
                            prosecution<sup>3</sup>.  In September 2021, Australia banned medical practitioners from
                            prescribing Ivermectin altogether<sup>4</sup>.
                        </p>
                        <p>
                            These drugs, and others, are now included in early patient treatment protocols<sup>5</sup> that
                            are being used effectively<sup>6</sup> around the world.
                        </p>
                        <p>
                            Ask yourself, when have doctors ever faced imprisonment for trying to help patients?
                            Medicine is an art and a science, it takes judgement.  Ask yourself why, in the middle of a
                            global pandemic, we are not taking the opinions of the experts in their field seriously.
                        </p>
                        <p className="Source">
                            1 -&nbsp;
                            <a href="https://www.health.qld.gov.au/system-governance/legislation/cho-public-health-directions-under-expanded-public-health-act-powers/prescribing-dispensing-or-supply-of-hydroxychloroquine-direction">
                                https://www.health.qld.gov.au/system-governance/legislation/cho-public-health-directions-under-expanded-public-health-act-powers/prescribing-dispensing-or-supply-of-hydroxychloroquine-direction</a>
                            <br />
                            2 -&nbsp;
                            <a href="https://www.leparisien.fr/societe/l-hydroxychloroquine-n-est-plus-autorisee-en-france-contre-le-covid-19-27-05-2020-8324493.php">
                                https://www.leparisien.fr/societe/l-hydroxychloroquine-n-est-plus-autorisee-en-france-contre-le-covid-19-27-05-2020-8324493.php</a>
                            <br />
                            3 -&nbsp;
                            <a href="https://newsinfo.inquirer.net/1426194/pma-warns-doctors-do-not-prescribe-ivermectin-outside-permitted-hospitals-or-face-prosecution">
                                https://newsinfo.inquirer.net/1426194/pma-warns-doctors-do-not-prescribe-ivermectin-outside-permitted-hospitals-or-face-prosecution</a>
                            <br />
                            4 -&nbsp;
                            <a href="https://www.theguardian.com/australia-news/2021/sep/10/australian-drug-regulator-bans-ivermectin-as-covid-treatment-after-sharp-rise-in-prescriptions">
                                https://www.theguardian.com/australia-news/2021/sep/10/australian-drug-regulator-bans-ivermectin-as-covid-treatment-after-sharp-rise-in-prescriptions</a>
                            <br />
                            5 -&nbsp;
                            <a href="https://www.amjmed.com/article/S0002-9343(20)30673-2/fulltext">
                                https://www.amjmed.com/article/S0002-9343(20)30673-2/fulltext</a>
                            <br />
                            6 -&nbsp;
                            <a href="https://www.imrpress.com/journal/RCM/21/4/10.31083/j.rcm.2020.04.260">
                                https://www.imrpress.com/journal/RCM/21/4/10.31083/j.rcm.2020.04.260</a>
                        </p>
                    </h3>
                </div>
            </div>
        </Panel>

        <div className="Quote">
            <Panel>
                <h1 style={{fontFamily: "Serif"}}>
                    "Everything currently happening is to promote fear, isolation, suffering, hospitalization, and death, in order to get a needle in every arm at all costs." - Dr. Peter McCullough
                </h1>
            </Panel>
        </div>

        <Panel className="Content top-border" id="conclusion">
            <div className="p-grid">
                <div className="p-col-12">
                    <img src={process.env.PUBLIC_URL + 'conclusion/truth.jpeg'} />
                    <h3>
                        <p>
                            The COVID-19 pandemic has been a global disaster.  Despite contagion control efforts, we
                            continue to see poor outcomes for hospitalization and death.  The pre-hospital phase is the time
                            for therapeutic intervention.  Early treatment with a multi-drug protocol
                            has been shown highly effective in reducing hospitalization and death.
                        </p>
                        <p>
                            <b>The vaccines are not as safe as we were assured they would be</b>b>.  From the fact this is
                            the first time mRNA vaccines have been used en masse in humans, to the unprecedented number of reported
                            adverse reactions and deaths, to the many ways the S-protein exhibits toxic effects in humans.
                        </p>
                        <p>
                            <b>The vaccines are not as effective as we were assured they would be</b>.  From initial estimates of
                            vaccine efficacy of 95%, effectiveness against symptomatic infection has dwindled down to as
                            low as 30%.  It is now clear that "booster" shots may be needed indefinitely.  Multiple
                            outbreaks of COVID-19 have produced cases predominantly in the fully vaccinated. Many
                            governments around the world still do not recognize immunity from natural infection.
                        </p>
                        <p>
                            Yet, governments around the world insist on pushing forward these vaccines.
                            Governments around the world continue to suppress and censor effective treatment protocols
                            that our medical experts have been using and collaborating on since the beginning of the pandemic.
                            Governments around the world continue to push forward with vaccination passports. Governments
                            around the world press on with "booster" shots, despite a profound lack of adequate
                            long-term safety data and the effect vaccines themselves have on the evolutionary pressure
                            of the virus.
                        </p>
                        <p>
                            The Nuremberg Code are a set of research ethics principles created as a result of human
                            experimentation by Nazi doctors during World War II. The principle of autonomy states: No
                            one, under any circumstances, will have anything forced
                            into their body under any form of pressure, coercion, of threat of reprisal.  Yet,
                            we are being <i>pressured</i>, we are being <i>coerced</i>, and we are being <i>threatened</i>.
                        </p>
                        <p>
                            <b>We need to start demanding the truth.</b>
                        </p>
                    </h3>
                </div>
            </div>
        </Panel>

        <ScrollTop threshold={200} />

    </div>
  );
}

export default App;
